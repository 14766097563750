var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "container-layout details-document-package",
            class: {
              "container-layout": _vm.$screen.width > 576,
              "container-mobile": _vm.$screen.width <= 576,
            },
          },
          [
            _c("loading", {
              attrs: {
                active: _vm.isLoading,
                loader: "bars",
                "is-full-page": "",
                color: "#00A09C",
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            }),
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "12" } }, [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.caption1) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass:
                                  "back-with-title cursol-pointer py-1",
                              },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-transparent",
                                      on: { click: _vm.handleClickBack },
                                    },
                                    [
                                      _c("arrow-left", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.65)",
                                          size: 22,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            color: "#2e495d",
                                            "font-weight": "bolder",
                                            "font-size": "16px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(163, "Back")) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("b-card-text", [
                              _c(
                                "div",
                                { staticClass: "container-field mt-3" },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _vm.$screen.width >= 992
                                        ? _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _vm.$screen.width >= 992
                                                ? _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            this.FormMSG(
                                                              11,
                                                              "Type to Search"
                                                            ),
                                                        },
                                                        model: {
                                                          value: _vm.filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filter = $$v
                                                          },
                                                          expression: "filter",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            {
                                                              staticClass:
                                                                "btn-search",
                                                            },
                                                            [
                                                              _vm.filter
                                                                .length === 0
                                                                ? _c("Search", {
                                                                    staticClass:
                                                                      "icon",
                                                                    attrs: {
                                                                      color:
                                                                        "#FFFFFF",
                                                                      size: 16,
                                                                      "stroke-width": 2.5,
                                                                    },
                                                                  })
                                                                : _c("X", {
                                                                    staticClass:
                                                                      "icon",
                                                                    attrs: {
                                                                      color:
                                                                        "#FFFFFF",
                                                                      size: 16,
                                                                      "stroke-width": 2.5,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.filter =
                                                                            ""
                                                                        },
                                                                    },
                                                                  }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.canEditExpense &&
                                      _vm.$screen.width >= 992
                                        ? _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-row",
                                                {
                                                  staticClass:
                                                    "mb-2 mr-2 d-flex align-items-center justify-content-end",
                                                },
                                                [
                                                  _vm.$screen.width >= 992
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "w-200-px d-flex justify-content-center align-items-center",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "-1px",
                                                          },
                                                          attrs: {
                                                            size: "md",
                                                            variant:
                                                              "custom-outline-primary",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.reviewMode,
                                                          },
                                                        },
                                                        [
                                                          _c("b-spinner", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.watingSubmitReviewMode,
                                                                expression:
                                                                  "watingSubmitReviewMode",
                                                              },
                                                            ],
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          }),
                                                          !_vm.watingSubmitReviewMode
                                                            ? _c(
                                                                _vm.getLucideIcon(
                                                                  "View"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    size: 20,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    164,
                                                                    "Review mode"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", xl: "12" } },
                                        [
                                          _c("CardListBuilder", {
                                            attrs: {
                                              "custom-class": "hide-on-desktop",
                                              items: _vm.expItems,
                                              fields: _vm.ticketFields,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "actions",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant:
                                                            data.item
                                                              .cardIconCheckVariant,
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onCardSelected(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "icon-check",
                                                        }),
                                                      ]
                                                    ),
                                                    data.item.images.length > 0
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn bg-transparent border-0",
                                                            attrs: {
                                                              variant:
                                                                "success",
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showPicture(
                                                                  data.item
                                                                    .images,
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS
                                                                  .PICTURE.name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .PICTURE
                                                                      .color,
                                                                  size: 20,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                          _c("b-table", {
                                            ref: "tableBudgetCategory",
                                            staticClass: "hide-on-tablet",
                                            attrs: {
                                              hover: _vm.hover,
                                              responsive: "sm",
                                              selectable: "",
                                              selectedVariant:
                                                _vm.selectedColor,
                                              "select-mode": _vm.selectMode,
                                              items: _vm.expItems,
                                              fields: _vm.ticketFields,
                                              "current-page": _vm.currentPage,
                                              "sticky-header": "700px",
                                              filter: _vm.filter,
                                              "per-page": _vm.perPage,
                                              "head-variant": _vm.hv,
                                              bordered: "",
                                              small: "",
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "cell(validatedStatus)",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "wrap-status",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: `status ${_vm.classStatus(
                                                              data.item
                                                                .validated
                                                            )}`,
                                                            staticStyle: {
                                                              "font-size":
                                                                "0.7rem",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                _vm._s(
                                                                  data.item
                                                                    .validatedStatus
                                                                ) +
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "cell(pict)",
                                                fn: function (data) {
                                                  return [
                                                    data.item.images.length > 0
                                                      ? _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn bg-transparent border-0",
                                                            attrs: {
                                                              variant:
                                                                "success",
                                                              size: "sm",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showPicture(
                                                                  data.item
                                                                    .images,
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS
                                                                  .PICTURE.name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .PICTURE
                                                                      .color,
                                                                  size: 20,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", xl: "5" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showSendXlsByEmail,
                                                  expression:
                                                    "showSendXlsByEmail",
                                                },
                                              ],
                                              staticClass: "px-3",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                              attrs: {
                                                size: "sm",
                                                variant: "primary",
                                                block: !_vm.$screen.md,
                                              },
                                              on: { click: _vm.sendXlsByEmail },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      20,
                                                      "Send me this Expense by Email"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      staticClass: "modal-success",
                                      attrs: {
                                        "header-class":
                                          "header-class-modal-doc-package",
                                        title: _vm.FormMSG(21, "Success!"),
                                        "ok-variant": "success",
                                        "ok-only": "",
                                      },
                                      on: {
                                        ok: function ($event) {
                                          _vm.successModal = false
                                        },
                                      },
                                      model: {
                                        value: _vm.successModal,
                                        callback: function ($$v) {
                                          _vm.successModal = $$v
                                        },
                                        expression: "successModal",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(
                                              22,
                                              "Email sent to your Email address."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-modal",
                                    {
                                      staticClass: "modal-danger",
                                      attrs: {
                                        "header-class":
                                          "header-class-modal-doc-package",
                                        title: _vm.FormMSG(
                                          24,
                                          "Expense item comment"
                                        ),
                                        "ok-variant": "danger",
                                        "ok-only": "",
                                      },
                                      on: {
                                        ok: function ($event) {
                                          _vm.modalExpenseItemComment = false
                                        },
                                      },
                                      model: {
                                        value: _vm.modalExpenseItemComment,
                                        callback: function ($$v) {
                                          _vm.modalExpenseItemComment = $$v
                                        },
                                        expression: "modalExpenseItemComment",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", sm: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.FormMSG(
                                                      25,
                                                      "Comment:"
                                                    ),
                                                    "label-for": "comment",
                                                    "label-cols": 2,
                                                  },
                                                },
                                                [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      disabled: true,
                                                      id: "comment",
                                                      rows: "2",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.expenseItemComment,
                                                      callback: function ($$v) {
                                                        _vm.expenseItemComment =
                                                          $$v
                                                      },
                                                      expression:
                                                        "expenseItemComment",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("expense-sheet-carousel-view-modal", {
        ref: "expense-sheet-carousel-view-modal",
        attrs: {
          "expense-items-inline": "",
          items: _vm.expenses,
          "custom-expense": _vm.customExpense,
          "custom-expense-items": _vm.customExpenseItems,
          "launch-reload-card-view": false,
          "reload-data-after-img-inserted": false,
          "can-only-make-split-item": "",
        },
        on: {
          "expense-sheet-carousel-view-modal:closed":
            _vm.onExpenseSheetCarouselViewModalClosed,
          "expense-sheet-carousel-view-modal:reload":
            _vm.onExpenseSheetCarouselViewModalReloaded,
        },
        model: {
          value: _vm.isExpenseCarouselViewOpen,
          callback: function ($$v) {
            _vm.isExpenseCarouselViewOpen = $$v
          },
          expression: "isExpenseCarouselViewOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }