<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				class="container-layout details-document-package"
				:class="{
					'container-layout': $screen.width > 576,
					'container-mobile': $screen.width <= 576
				}"
			>
				<loading :active.sync="isLoading" loader="bars" is-full-page color="#00A09C" />
				<div class="form">
					<b-row>
						<b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ caption1 }}
							</h1>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<b-row class="back-with-title cursol-pointer py-1">
									<b-col cols="6">
										<button class="btn-transparent" @click="handleClickBack">
											<arrow-left color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
											<span style="display: inline-block; color: #2e495d; font-weight: bolder; font-size: 16px">
												{{ FormMSG(163, 'Back') }}
											</span>
										</button>
									</b-col>
								</b-row>
								<b-card-text>
									<div class="container-field mt-3">
										<b-row class="mb-2">
											<b-col cols="6" v-if="$screen.width >= 992">
												<b-input-group v-if="$screen.width >= 992">
													<b-form-input v-model="filter" type="text" :placeholder="this.FormMSG(11, 'Type to Search')" />
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
															<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
											<b-col v-if="canEditExpense && $screen.width >= 992">
												<b-row class="mb-2 mr-2 d-flex align-items-center justify-content-end">
													<b-button
														size="md"
														variant="custom-outline-primary"
														class="w-200-px d-flex justify-content-center align-items-center"
														@click="reviewMode"
														style="margin-top: -1px"
														v-if="$screen.width >= 992"
													>
														<b-spinner class="mr-2" v-show="watingSubmitReviewMode" small />
														<component v-if="!watingSubmitReviewMode" :is="getLucideIcon('View')" :size="20" class="mr-2" />
														<div style="margin-top: 2px">{{ FormMSG(164, 'Review mode') }}</div>
													</b-button></b-row
												>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12" xl="12">
												<!--      for expense and invoices -->
												<CardListBuilder custom-class="hide-on-desktop" :items="expItems" :fields="ticketFields">
													<template slot="actions" slot-scope="data">
														<b-button :variant="data.item.cardIconCheckVariant" size="sm" @click="onCardSelected(data.item)">
															<i class="icon-check"></i>
														</b-button>
														<b-button
															variant="success"
															v-if="data.item.images.length > 0"
															size="sm"
															class="btn bg-transparent border-0"
															@click="showPicture(data.item.images, data.item)"
														>
															<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
														</b-button>
													</template>
												</CardListBuilder>
												<b-table
													class="hide-on-tablet"
													:hover="hover"
													responsive="sm"
													ref="tableBudgetCategory"
													selectable
													:selectedVariant="selectedColor"
													:select-mode="selectMode"
													:items="expItems"
													:fields="ticketFields"
													:current-page="currentPage"
													@row-clicked="rowClicked"
													sticky-header="700px"
													:filter="filter"
													:per-page="perPage"
													:head-variant="hv"
													bordered
													small
												>
													<!-- <template slot="pict" slot-scope="data"> -->
													<template v-slot:cell(validatedStatus)="data">
														<div class="wrap-status">
															<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
																{{ data.item.validatedStatus }}
															</div>
														</div>
													</template>
													<template v-slot:cell(pict)="data">
														<b-button
															variant="success"
															v-if="data.item.images.length > 0"
															size="sm"
															class="btn bg-transparent border-0"
															@click="showPicture(data.item.images, data.item)"
														>
															<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
														</b-button>
													</template>
												</b-table>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12" xl="5">
												<b-button
													@click="sendXlsByEmail"
													v-show="showSendXlsByEmail"
													size="sm"
													variant="primary"
													:block="!$screen.md"
													style="margin-right: 10px"
													class="px-3"
												>
													{{ this.FormMSG(20, 'Send me this Expense by Email') }}
												</b-button>
											</b-col>
										</b-row>
										<b-modal
											header-class="header-class-modal-doc-package"
											:title="FormMSG(21, 'Success!')"
											class="modal-success"
											v-model="successModal"
											@ok="successModal = false"
											ok-variant="success"
											ok-only
										>
											{{ this.FormMSG(22, 'Email sent to your Email address.') }}
										</b-modal>
										<b-modal
											header-class="header-class-modal-doc-package"
											:title="FormMSG(24, 'Expense item comment')"
											class="modal-danger"
											v-model="modalExpenseItemComment"
											@ok="modalExpenseItemComment = false"
											ok-variant="danger"
											ok-only
										>
											<b-row>
												<b-col cols="12" sm="12">
													<b-form-group :label="FormMSG(25, 'Comment:')" label-for="comment" :label-cols="2">
														<b-form-textarea :disabled="true" id="comment" v-model="expenseItemComment" rows="2"></b-form-textarea>
													</b-form-group>
												</b-col>
											</b-row>
										</b-modal>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
		<expense-sheet-carousel-view-modal
			ref="expense-sheet-carousel-view-modal"
			v-model="isExpenseCarouselViewOpen"
			expense-items-inline
			:items="expenses"
			:custom-expense="customExpense"
			:custom-expense-items="customExpenseItems"
			:launch-reload-card-view="false"
			:reload-data-after-img-inserted="false"
			can-only-make-split-item
			@expense-sheet-carousel-view-modal:closed="onExpenseSheetCarouselViewModalClosed"
			@expense-sheet-carousel-view-modal:reload="onExpenseSheetCarouselViewModalReloaded"
		/>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { rendCurrency, rendKgCo2 } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import ExpenseService from '@/components/ExpenseService/Main';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { getExpenseItemsForDepartmentAndCategoryList } from '@/cruds/expense.crud.js';
import { isNil } from '~utils';
import { getFileExtension } from '@/shared/helpers';
// import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import { classStatus } from '@/shared/global-status';
import { Search, X, ArrowLeft } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';
import * as _ from 'lodash';
import moment from 'moment';
import ExpenseSheetCarouselViewModal from '@/components/ExpenseService/ExpenseSheetCarouselViewModal';

export default {
	name: 'ExpenseItemsDepCat',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading,
		ExpenseService,
		Search,
		X,
		ArrowLeft,
		ExpenseSheetCarouselViewModal
	},
	async created() {
		if (this.curDay.date.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.reloadData();
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			curBudgetDataDepCat: {},
			curExpItem: {},
			curMonthStr: '',
			expenseItemImagePath: [],
			oneItem: '',
			expItems: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			expenseItemPicture: false,
			openShowRoomPicModal: false,
			modalExpenseItemComment: false,
			expenseItemComment: '',
			filter: '',
			isLoading: false,
			selectMode: 'single',
			selectedColor: 'primary',
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curDay: {
				id: 0,
				date: '',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			newCurDay: {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			showSendXlsByEmail: false,
			expenseModalRefreshToken: 0,
			expenseType: 0,
			currEditExpense: null,
			moment,
			isExpenseCarouselViewOpen: false,
			expenses: [],
			customExpense: null,
			customExpenseItems: null,
			watingSubmitReviewMode: false
		};
	},
	computed: {
		ticketFields() {
			return [
				{
					key: 'id',
					label: '#' + this.FormMSG(52, 'Id'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(51, 'Status'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'date',
					label: this.FormMSG(53, 'Date'),
					formatter: (value) => {
						return moment(value).utc().format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(54, 'Description'),
					sortable: true
				},
				{
					key: 'supplierName',
					label: this.FormMSG(60, 'Supplier'),
					class: 'text-center',
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(64, 'Name'),
					sortable: true
				},
				{
					key: 'paiementTypeName',
					label: this.FormMSG(62, 'Paid'),
					class: 'text-center',
					sortable: false
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(61, 'Amount'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (value) => {
						return rendKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				}
			];
		},
		caption1() {
			const res = `
					${this.FormMSG(1, 'Expenses detail for department')}: ${this.curBudgetDataDepCat ? this.curBudgetDataDepCat.departmentName : ''} / ${
				this.curBudgetDataDepCat ? this.curBudgetDataDepCat.costCenter + ' - ' + this.curBudgetDataDepCat.budgetDetail.description : ''
			}
			`;

			return res;
		},
		isProd() {
			return store.isProd();
		},
		canEditExpense() {
			return store.canEditExpense();
		}
	},
	methods: {
		handleClickBack() {
			if (this.$route.query.budgetPerCategory) {
				this.$router.push({ path: `/budget?activeTabPerCategory=1&search=${this.$route.query.search}` });
			} else {
				this.$router.go(-1);
			}
		},
		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		rowClicked(item, index) {
			if (this.canEditExpense) {
				const isCategory = new RegExp('(category)', 'gi').test(this.$router.currentRoute.path);
				this.$nextTick(function () {
					this.showSendXlsByEmail = this.$refs.tableBudgetCategory.isRowSelected(index);
				});
				this.curExpItem = item;
				// console.log("row item :", this.curExpItem);
				if (item.comment.length > 0) {
					this.expenseItemComment = item.comment;
					this.modalExpenseItemComment = true;
				}

				if (this.isProd && isCategory) {
					this.expenseType = item.type;
					this.currEditExpense = item;
				}

				this.customExpense = this.customPropExpense(item);

				this.isLoading = true;
				setTimeout(() => {
					this.isExpenseCarouselViewOpen = true;
					this.isLoading = false;
				}, 1000);
			}
		},
		customPropExpense(item) {
			const index = this.expenses.findIndex((expense) => +expense.id === +item.expenseId) + 1;
			const expense = this.expenses.filter((expense) => +expense.id === +item.expenseId);

			this.customExpenseItems = this.customPropExpenseItems(expense[0], item);

			return {
				index,
				data: expense[0]
			};
		},
		customPropExpenseItems(expense, item) {
			const index = expense.items.findIndex((expenseItem) => +expenseItem.id === +item.id) + 1;
			const items = expense.items;

			return {
				index,
				data: items
			};
		},
		clearCardSelected() {
			const cloneExpItems = this.expItems;
			cloneExpItems.forEach((expItem, index) => {
				expItem.cardIconCheckVariant = 'outline-primary';
				expItem.isCardChecked = false;
				this.$set(this.expItems, index, expItem);
			});
		},
		onCardSelected(item) {
			//clear before apply change
			if (this.curExpItem.id !== item.id) {
				this.clearCardSelected();
			}
			// change
			item.isCardChecked = !item.isCardChecked;
			if (item.isCardChecked) {
				item.cardIconCheckVariant = 'warning';
			} else {
				item.cardIconCheckVariant = 'outline-primary';
			}
			this.$set(
				this.expItems,
				this.expItems.findIndex((expItem) => expItem.id === item.id),
				item
			);
			this.showSendXlsByEmail = item.isCardChecked;

			this.curExpItem = item;
			// console.log("row item :", this.curExpItem);
			if (item.comment.length > 0) {
				this.expenseItemComment = item.comment;
				this.modalExpenseItemComment = true;
			}
		},
		firstTimeInitialisation() {
			this.curExpItem.expenseId = 0;
			this.curBudgetDataDepCat = store.getCurBudgetDataForDepartmentCategory();
			//console.log("firstInit CurDepCat:", this.curBudgetDataDepCat)
		},
		updateTZdataEach(expItemData) {
			// setting row color
			expItemData.validatedStatus = this.validatedText(expItemData.validated);
			expItemData.fullName = expItemData.user.name + ' ' + expItemData.user.firstName;
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		async reloadData() {
			this.isLoading = true;
			//  this.curBudgetDataDepCat = store.getCurBudgetDataForDepartmentCategory();
			// console.log('this.curBudgetDataDepCat: ', this.$route.params);
			const { department, category } = this.$route.params;
			const departmentId = parseInt(department, 10); // this.curBudgetDataDepCat.department;
			const categoryId = parseInt(category, 10); // this.curBudgetDataDepCat.category;
			await getExpenseItemsForDepartmentAndCategoryList(departmentId, categoryId)
				.then((records) => {
					records.forEach(this.updateTZdataEach);
					this.expItems = records;
					this.expItems.map((expItem) => {
						expItem.cardIconCheckVariant = 'outline-primary';
						expItem.isCardChecked = false;

						return expItem;
					});

					this.getExpenses(this.expItems);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				})
				.finally(() => (this.isLoading = false));
		},
		async sendXlsByEmail() {
			this.isLoading = true;
			this.warning = '';

			if (this.curExpItem.expenseId == 0) {
				return;
			}

			const idToPrint = this.curExpItem.expenseId;
			const fullPath = process.env.VUE_APP_GQL + '/expenseReport/' + idToPrint;
			// console.log("fullpath", fullPath);
			// console.log("Bearer + store.state.token:", "Bearer " + store.state.token);
			const config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};
			await axios
				.get(fullPath, config)
				.then((response) => {
					// console.log({
					// 	response,
					// });
					if (response.status == 200) {
						this.successModal = true;
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = 'No Expense.';
						}
					}
				})
				.finally(() => (this.isLoading = false));
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		reviewMode() {
			this.watingSubmitReviewMode = true;
			setTimeout(() => {
				this.isExpenseCarouselViewOpen = true;
				this.watingSubmitReviewMode = false;
			}, 500);
		},
		getExpenses(expenseItems) {
			// expenses
			let expenses = [],
				expense = null,
				expenseId = 0,
				expenseItem = null;

			for (const item of expenseItems) {
				expenseId = +item.expenseId;
				const index = expenses.findIndex((data) => +data.id === expenseId);
				if (index === -1) {
					expense = item.expense;
					expense.type = item.type;
					expense.user = item.user;
					expense.fullName = item.user.name + ' ' + item.user.firstName;
					expense.functionName = item.user.functionName;
					expense.departmentName = item.user.departmentName;
					expenseItem = item;
					expenseItem = _.omit(expenseItem, ['expense', 'fullName', 'paiementTypeName', 'supplierName', 'categoryName']);
					expense.items = [];
					expense.items.push(expenseItem);
					expenses.push(expense);
				} else {
					expenseItem = item;
					expenseItem = _.omit(expenseItem, ['expense', 'fullName', 'paiementTypeName', 'supplierName', 'categoryName']);
					expenses[index].items.push(expenseItem);
				}
			}

			this.expenses = expenses;
		},
		onExpenseSheetCarouselViewModalClosed() {
			this.isExpenseCarouselViewOpen = false;
		},
		async onExpenseSheetCarouselViewModalReloaded() {
			await this.reloadData();
		}
	}
};
</script>
